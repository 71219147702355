@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:900);
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Redressed&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

.home-title {
  font-family: "Graduate", serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  color: #ac6429;
  font-size: 50px;
  margin-top: 30px;
}
  body {
    padding: 0;
    font-family: 'Montserrat', serif;
    height: 100%;
    margin: 0;
    background: linear-gradient(0deg, #0a0a0a 1%, #121111 28%, #313030 80%) no-repeat fixed;
  }

  header {
    padding: 0;
    height: 100px;
    margin: 20px 50px 10px;
  }
  .column-three {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-gap: 20px;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-rule: 2px none #e11717;
    -webkit-column-rule: 2px none #e11717;
    -moz-column-rule: 2px none #e11717;
  }

  #headerTitle {
    font-size: 75px;
    font-family: 'Roboto Thin', sans-serif;
    color: #f9f9fa;
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  .top-description {
    padding: 20px;
  }
  .top-description > p,
  .top-description ul{
    font-size: 1.8rem;
    color: whitesmoke;
    display: block;
    margin: 1px;
  }
  .top-description ul {
    font-size: 1.5rem;
  }
  .ul-description li{
    padding: 1px;
  }
  .leader-panel {
    position:absolute;
    top:0;
    right:0;
  }
  .menu-drawer {
    position:absolute;
    top:0;
    left:0;
  }
  .left,
  .middle,
  .right {
    padding: 10px;
  }

  /* Style the left column */
  .left {
    grid-area: left;
    background: url(https://backgroundcheckall.com/wp-content/uploads/2017/12/sleek-background-300x200.jpg);
    mix-blend-mode: difference;
    width: 530px;
  }

  /* Style the middle column */
  .middle {
    grid-area: middle;
  }
  .middle#grid3 {
    margin: 15px;
    width: 440px;
  }
  #left.left,
  #right.right {
    width: 450px;
  }
  /* Style the right column */
  .right {
    grid-area: right;
    right: 0;
    margin: 0;
  }
  .mid-header {
    color: #040000;
    text-align: center;
    font-family: "Gill Sans", sans-serif;
    font-size: 20px;
    -webkit-text-stroke-width: thin;
  }
  h3.mid-header {
    margin-top: 50px;
  }
  .left-header {
    color: #040000;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    -webkit-text-stroke-width: thin;
  }
  .right-header {
    color: #040000;
    text-align: right;
    align-content: end;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    -webkit-text-stroke-width: thin;
  }
  .paper-stack {
    margin-top: 40px;
  }