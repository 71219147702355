.notes-page-title {
    font-family: "Graduate", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: #ac6429;
    font-size: 50px;
    margin-top: 15px;
    font-optical-sizing: auto;
    flex-grow: 1;
    display: flex;
    position: fixed;
    z-index: 6;
    top: 0;
    right: 45%;
    overflow-x: hidden;
    padding-top: 20px;
}
span {
    margin: 2px;
}
.bold-label {
    font-weight: bold;
}

.center-page {
    text-align: center;
    margin: 50px;
}
.border-box {
    border: 1px solid gray;

}

.note-column {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-gap: 20px;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-rule: 2px none #e11717;
    -webkit-column-rule: 2px none #e11717;
    -moz-column-rule: 2px none #e11717;
    display: flex;
    flex-direction: row;
}

.div-icon {
    text-align: center;
}

.note-body {
    text-align: center;
    color: #d30b0b;
    margin-bottom: 5px;
    font-family: "Trirong", sans-serif;
}

.note-description {
    text-align: center;
    color: #040000;
    font-weight: bold;
}
.reason-description {
    display: block;
    color: #0e8e05;
    font-weight: bold;
}
.note-expand-title {
    font-weight: bold;
    font-size: 20px;
    display: block;
    margin-top: 6px;
}
h3 {
    text-align: center;
    color: #5c5656;
    margin: 10px;
    font-weight: bolder;
}

.help-text {
    position: absolute;
    right: 0;
}

.card-summary {
    /*text-align: center;*/
    color: #031b40;
}

.date-title {
    text-align: center;
    color: whitesmoke;
}

@media only screen and (min-width: 960px)  and (max-width: 1140px){

    body { min-width:960px; }

}

@media only screen and (min-width: 768px) and (max-width: 959px) {

    body { min-width:768px; }

}

@media only screen and (min-width: 480px) and (max-width: 767px) {

    body {
        min-width: 480px;
    }

}

