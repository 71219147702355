.column-header {
    color: #0b0101;
    font-weight: lighter;
    font-family: "Oswald", sans-serif;
    font-size: large;
}
.stats-title {
    font-family: "Graduate", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: #ac6429;
    font-size: 50px;
    margin-top: 15px;
    font-optical-sizing: auto;
    flex-grow: 1;
    display: flex;
    position: fixed;
    z-index: 6;
    top: 0;
    right: 30%;
    overflow-x: hidden;
    padding-top: 20px;
}

@media only screen and (min-width: 960px) and (max-width: 1140px) {
    .stats-title {
        font-size: 30px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .stats-title {
        font-size: 25px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .stats-title {
        font-size: 20px;
    }
}