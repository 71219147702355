.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #59059f;
}

.App-link {
  color: #484a4a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
    text-align: center;
    width: 30vw;
    background-color: rgb(89, 5, 159);
    padding: 2vw 1vw;
    border-radius: 10px 10px 0 0;
    font-size: 2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: flex;
    flex-direction: column;
    margin: auto;
    color: white;
}

.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 30vw;
    padding: 2vw 1vw;
    background-color: rgb(0, 0, 0);
    border-radius: 0 0 10px 10px;
}

input {
    border: 1px solid rgb(72, 74, 74);
    border-radius: 10px;
    padding: 1vw 1vw;
    outline: none;
    margin: 5px;
}