.box-columns {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: whitesmoke;
}

.container-description {
    li {
        font-family: "Bai Jamjuree", sans-serif;
        font-size: 16px;
        text-align: center;
    }
}

.box {
    display: flex;
    flex-wrap: wrap;
    margin-left: 60px;
}

.box > * {
    flex: 1 1 100px;
}

.container-label {
    font-family: Graduate, sans-serif;
    text-align: center;
    font-size: 4vmin;
    color: #a31a1a;
    margin-top: 40px;
}

@media only screen and (min-width: 960px) and (max-width: 1140px) {


}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .box {
        span {
            font-size: 12px;
        }
    }
    .container-description {
    li {
        font-size: 14px;
    }
}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .box {
        span {
            font-size: 12px;
        }
    }
    .container-description {
    li {
        font-size: 12px;
    }
}
}