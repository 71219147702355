.team-title {
    color: whitesmoke;
    margin-bottom: 20px;
}

tspan {
    fill: rgba(255, 255, 255, 0.86);
}

.choice-bar {
    margin-top: 15px;
}

.stats-container {
    text-align: center;
    align-content: center;
    color: whitesmoke;
    margin-top: 20px;
    margin-left: 50px;
    height: 180px;
    width: 180px;
    display: inline;
}

.trend-title {
    font-family: "Foldit", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    /*font-size: 70px;*/
    font-size: 5vw;
    flex-grow: 1;
    display: flex;
    position: fixed;
    z-index: 6;
    right: 40%;
    overflow-x: hidden;
    padding-top: 10px;
    margin-top: 5px;
}

@media only screen and (min-width: 960px) and (max-width: 1140px) {

}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .trend-title {
        right: 30%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .trend-title {
        font-size: 2em;
        right: 28%;
    }
}